import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import GatingIcon from "./gating/icon";
import GatingMessageInline from "./gating/message-inline";
import SvgJsx from "./svg/svg-jsx";
import { useContext } from "react";
import { TribeContext } from "../context/tribe-context";

export default function SelectListItem({
  set_selected_value,
  selected_value,
  value,
  text,
  premium_level = 0,
  premium_id = "",
  premium_tip = `Contributor Only`,
  userdata = { contributor_level: 0 },
  recommended = null,
  iconClassName = `h-7 w-7 mr-2 `,
  itemClassName = `my-2 rounded-md px-4 py-2 flex items-top content-top group hover:shadow-md`,
  selectedClassName = `bg-blue-600 hover:bg-blue-500`,
  unselectedClassName = `bg-gray-700 hover:bg-gray-600`,
  iconIcon = "arrow-narrow-right",
  iconType = "outline",
  show_icon = true,
}) {
  const { isConfirmed } = useConfirm();

  const ssr_data_tribe = useContext(TribeContext);

  return (
    <>
      <div
        onClick={async () => {
          if (
            ssr_data_tribe?.bol_contribute?.toString() == "1" &&
            premium_level > userdata?.contributor_level
          ) {
            await isConfirmed({
              title: `This option is locked`,
              body: (
                <>
                  <GatingMessageInline
                    headline={<>Contributor Only Option</>}
                    text={
                      <>Kindly consider becoming a contributor to unlock it.</>
                    }
                  />
                </>
              ),
              confirm: ``,
              cancel: `Close`,
              show_title: false,
              show_cancel: true,
              show_confirm: false,
            });
          } else {
            set_selected_value();
          }
        }}
        className={`${itemClassName}
                                ${
                                  ssr_data_tribe?.bol_contribute?.toString() ==
                                    "1" &&
                                  premium_level > userdata?.contributor_level
                                    ? "cursor-not-allowed"
                                    : "cursor-pointer"
                                } 
                                                              
                               ${
                                 selected_value == value
                                   ? selectedClassName
                                   : unselectedClassName
                               } 
                                 text-gray-200
                               `}
      >
        {show_icon && (
          <div
            className={`flex-0  
                                     ${
                                       selected_value == value
                                         ? ` opacity-100 `
                                         : ` opacity-0 group-hover:opacity-50 `
                                     }`}
          >
            <SvgJsx
              type={iconType}
              icon={iconIcon}
              className={iconClassName}
              title={"selected"}
            />
          </div>
        )}
        <div
          className={`${show_icon && "ml-2"} ${
            selected_value == value ? ` font-bold ` : `  `
          } flex-1`}
        >
          {text}
        </div>
        {value == recommended && (
          <div className="text-blue-300  font-bold text-xs mt-2">
            RECOMMENDED
          </div>
        )}
        {ssr_data_tribe?.bol_contribute?.toString() == "1" &&
          premium_level > 0 && (
            <div className="flex-0 ml-2">
              <GatingIcon
                id={premium_id}
                tip={premium_tip}
                classSize={`w-6 h-6`}
              />
            </div>
          )}
      </div>
    </>
  );
}
