import LoadingRotary from "./loading/loading-rotary";

export default function RotaryLogo({
  classSize = "w-7 h-7 mx-auto ",
  classAnimate = " animate-spin-slow",
  classAnimateUnder = "",
  bgColor = "transparent",
  centerColor = "#111827",
  pointerColor = "#ff0000",
  holeColor = "#111827",
  dialerColor = "#ffffff",
  size = "64",
  title = null,
}) {
  //USAGE: Add a "group" class to any outer container calling this component
  // to trigger the group-hover animation.

  return (
    <>
      <div className={`relative flex-0 flex ${classSize}`}>
        <div className="absolute">
          <LoadingRotary
            classSize={classSize}
            classAnimate={classAnimate}
            bgColor={bgColor}
            centerColor={centerColor}
            pointerColor={pointerColor}
            holeColor={holeColor}
            dialerColor={dialerColor}
            size={size}
            title={title}
          />
        </div>
        <div className="absolute opacity-100 group-hover:opacity-0">
          <LoadingRotary
            classSize={classSize}
            classAnimate={classAnimateUnder}
            bgColor={bgColor}
            centerColor={centerColor}
            pointerColor={pointerColor}
            holeColor={holeColor}
            dialerColor={dialerColor}
            size={size}
            title={title}
          />
        </div>
      </div>
    </>
  );
}
