import { hasData } from "./hasData";

export function IsJsonValid(str) {
  if (!hasData(str)) return false;
  try {
    JSON.parse(str);
  } catch (e) {
    // console.log(e)
    return false;
  }
  return true;
}

export function IsJson(str) {
  if (Array.isArray(str)) return true;
  return false;
}

export function validJson(str, alt = null) {
  if (IsJson(str)) return str;
  if (IsJsonValid(str)) return JSON.parse(str);
  return alt;
}
