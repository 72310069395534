import ButtonPrimary from "@/components/templateux/button/button-primary";
import LoaderToggle from "@/components/templateux/loading/loader-toggle";

export default function SettingsSave({
  submitting_state,
  actionText = "Save changes",
  actionTextShort = "Save changes",
  className = " whitespace-nowrap flex text-xl cursor-pointer animate-save text-white border border-transparent hover:border-white rounded-md  flex-0  text-center px-3 py-1.5 font-bold   shadow-md",
  button_type = "submit",
  changes = false,
}) {
  var var_className = `${className}
                        ${changes ? " animate-save " : ""}
                        `;

  return (
    <>
      <div className="mt-4">
        <ButtonPrimary
          disabled={submitting_state}
          type={button_type}
          className={var_className}
          override_class={true}
        >
          <LoaderToggle
            loadingstate={submitting_state}
            actionText={actionText}
            actionTextShort={actionTextShort}
          />
        </ButtonPrimary>
      </div>
    </>
  );
}
