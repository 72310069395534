import LoadingRotary from "../loading/loading-rotary";
import Image from "next/legacy/image";
import { getAvatarUrl } from "@/lib/utils/getAvatarUrl";

function AvatarFlairSizes({
  isPlaying = false,
  isContributor = false,
  isBot = false,
  avatar_url,
  avatar_url_sfw,
  bol_nsfw,
  avatar_crdate,
  id,
  user_name,
  showReminder = false,
  size, //tiny
}) {
  const imageLoader = ({ src, width, quality }) => {
    return `${getAvatarUrl(
      avatar_url,
      avatar_url_sfw,
      bol_nsfw,
      avatar_crdate,
      "",
      id
    )}?w=${width}&q=${quality || 75}`;
  };

  const avatarClasses = (size) => {
    return {
      micro: {
        containerClass: "w-5 h-5",
        bgClass: "w-5 h-5",
        avatarClass: "w-5 h-5 border border-transparent ",
        rotaryContainerClass: "w-5 h-5 left-[5px] ",
        rotaryInternalClass: "w-8 h-8",

        quality: 50,
      },
      tiny: {
        containerClass: "w-6 h-6",
        bgClass: "w-6 h-6",
        avatarClass: "w-6 h-6 border-2 border-transparent ",
        rotaryContainerClass: "w-6 h-6 left-[5px] ",
        rotaryInternalClass: "w-8 h-9",
        quality: 50,
      },
      md: {
        containerClass: "w-8 h-8",
        bgClass: "w-8 h-8",
        avatarClass: "w-8 h-8 border-2 border-transparent ",
        rotaryContainerClass: "w-8 h-8 left-[5px] ",
        rotaryInternalClass: "w-10 h-10",
        quality: 50,
      },
      lg: {
        containerClass: "w-10 h-10",
        bgClass: "w-10 h-10",
        avatarClass: "w-10 h-10 border-2 border-transparent ",
        rotaryContainerClass: "w-10 h-10 left-[7px] ",
        rotaryInternalClass: "w-12 h-12",
        quality: 50,
      },

      xl: {
        containerClass: "w-12 h-12",
        bgClass: "w-12 h-12",
        avatarClass: "w-12 h-12 border-2 border-transparent ",
        rotaryContainerClass: "w-12 h-12 left-[7px] ",
        rotaryInternalClass: "w-14 h-14",
        quality: 50,
      },
    }[size];
  };

  if (size == "none") return <></>;

  return (
    <>
      <div
        className={`${
          avatarClasses(size).containerClass
        } relative cursor-pointer group`}
      >
        {/* EMPTY BG DIV FOR BORDER */}
        <div
          className={`${
            avatarClasses(size).bgClass
          } absolute flex items-center content-center ring  ring-offset
           bg-[#111827] 
                                  ${
                                    isBot
                                      ? "ring-gray-500 rounded-md" //bot
                                      : isContributor //subscriber
                                      ? isPlaying
                                        ? showReminder
                                          ? "ring-[#ffffffff] group-hover:ring-[#ffffffaa] ring-offset-[#111827] rounded-full " //sub playing on Reminder banner
                                          : "ring-[#ffffffff] group-hover:ring-[#ffffffaa] ring-offset-[#111827] rounded-full " //sub playing everywhere
                                        : "ring-[#ffffffff] group-hover:ring-[#ffffffaa] ring-offset-[#111827] rounded-full " //sub not playing
                                      : isPlaying //unpaid
                                      ? showReminder
                                        ? "ring-white rounded-full ring-offset-white" //unpaid playing on Reminder banner
                                        : "ring-red-500 rounded-full ring-offset-red-500" //unpaid playing everywhere
                                      : "ring-gray-500 rounded-full ring-offset-gray-500" //unpaid not playing
                                  }
                                  
                                  `}
        ></div>

        {/* AVATAR */}
        <div
          className={`${
            avatarClasses(size).avatarClass
          } absolute flex items-center content-center ${
            !isBot ? "rounded-full" : " rounded-md "
          }  overflow-hidden
                                        ${isPlaying ? "animate-spin-slow" : ""}
                                `}
        >
          <Image
            loader={imageLoader}
            src={getAvatarUrl(
              avatar_url,
              avatar_url_sfw,
              bol_nsfw,
              avatar_crdate,
              "",
              id
            )}
            alt={user_name}
            title={user_name}
            quality={avatarClasses(size).quality}
            placeholder="empty"
            loading="lazy"
            layout="fill"
            //blurDataURL={config.avatar.blurdataurl}
            className={` object-cover ${
              !isBot ? "rounded-full" : " rounded-md"
            } overflow-hidden flex-none  bg-gray-200 `}
            // style={
            //     isPlaying
            //       ? {
            //           backgroundImage: `url("/images/app/live-64x64.gif")`
            //           , backgroundRepeat: `no-repeat`
            //           ,backgroundSize: `cover`
            //           ,backgroundPosition: `center`
            //         }
            //       : {}
            //   }
          />
        </div>

        <div
          className={`${
            avatarClasses(size).rotaryContainerClass
          } flex items-center content-center absolute 
                                `}
        >
          <LoadingRotary
            classSize={avatarClasses(size).rotaryInternalClass}
            classAnimate=""
            bgColor="transparent"
            centerColor="transparent"
            pointerColor={
              isBot
                ? "transparent" //bot
                : isContributor //subscriber
                ? isPlaying
                  ? showReminder
                    ? "#ff0000" //sub playing on Reminder banner
                    : "#ff0000" //sub playing everywhere
                  : "#ff0000" //sub not playing
                : isPlaying //unpaid
                ? showReminder
                  ? "transparent" //unpaid playing on Reminder banner
                  : "transparent" //unpaid playing everywhere
                : "transparent" //unpaid not playing
            }
            holeColor="transparent"
            dialerColor="transparent"
            size="64"
            title={user_name}
          />
        </div>
      </div>
    </>
  );
}

export default AvatarFlairSizes;
