import { config } from "../config";
import { hasData } from "./hasData";
import { srcSafe } from "./srcSafe";

export function getAvatarUrl(
  avatar_url,
  avatar_url_sfw,
  bol_nsfw,
  avatar_crdate,
  override_url = "",
  user_id
) {
  return srcSafe(
    validImage(avatar_url) && bol_nsfw !== 1
      ? `${avatar_url}?c=${avatar_crdate}`
      : validImage(avatar_url_sfw)
      ? avatar_url_sfw
      : validImage(override_url)
      ? override_url
      : config.avatar.url_missing
  );
}

function validImage(url) {
  if (!hasData(url)) return false;
  if (url.indexOf("://") > 0 || url.indexOf("//") === 0) return true;
  if (url.substring(0, 1) == "/") return true;
  return false;
}
