import parse from "html-react-parser";
import { hasData } from "./hasData";

export default function parsehtml(rawhtml, trusted = false) {
  if (!hasData(rawhtml)) return <></>;

  const noscript = <div>noscript</div>;

  const parseoptions = {
    replace: (domNode) => {
      const { attribs, children } = domNode;
      // console.dir(domNode, { depth: null });
      //if (domNode instanceof Element && attribs) {
      if (!attribs) {
        return;
      }

      //remove any scripts that are here...
      if (domNode.name === "script" && trusted == false) {
        return <></>;
      }

      //remove any scripts that are here...
      if (domNode.name === "iframe" && trusted == false) {
        return <></>;
      }

      //}
    },
  };

  return parse(rawhtml, parseoptions);
}
