import { hasData } from "./hasData";

export const srcSafe = (url) => {
  if (!hasData(url)) return url;

  var varURL = url;

  //try 2 approaches to how the file may be structured
  varURL = varURL?.replace(
    `${process.env.NEXT_PUBLIC_AWS_S3_DOMAIN}/${process.env.NEXT_PUBLIC_AWS_S3BUCKET}`,
    process.env.NEXT_PUBLIC_AWS_CLOUDFRONT_DOMAIN
  );

  varURL = varURL?.replace(
    `${process.env.NEXT_PUBLIC_AWS_S3BUCKET}.${process.env.NEXT_PUBLIC_AWS_S3_DOMAIN}`,
    process.env.NEXT_PUBLIC_AWS_CLOUDFRONT_DOMAIN
  );

  return varURL;
};
