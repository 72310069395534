import ReactTooltip from "react-tooltip";
import showdown from "showdown";
import parsehtml from "@/lib/utils/parsehtml";
import { support_messages } from "@/lib/config";
import RotaryLogo from "../rotary-logo";
import { useEffect, useState } from "react";

const GatingIcon = ({
  id,
  tip = `Premium`,
  classSize = `w-6 h-6`,
  placement = "right",
  show_tip = true,
}) => {
  var converter = new showdown.Converter();

  const [isMounted, setIsMounted] = useState(false); // Need this for the react-tooltip

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      <div className={`relative ${classSize}`}>
        <div className={`absolute ${classSize}`}>
          <RotaryLogo
            classSize={`${classSize} mx-auto  block`}
            classAnimate="animate-spin"
            bgColor="transparent"
            centerColor="rgba(31,41,55,1)"
            pointerColor="#ff0000"
            holeColor="rgba(31,41,55,1)"
            dialerColor="#ffffff"
            size="32"
          />
        </div>
        <div
          data-tip
          data-for={`helptip-${id}`}
          className={`${classSize} absolute cursor-pointer`}
        ></div>
      </div>
      {isMounted && show_tip && (
        <ReactTooltip
          id={`helptip-${id}`}
          //@ts-ignore
          place={`${placement}`}
          effect="solid"
          clickable={true}
          delayHide={0}
          delayUpdate={0}
          className="reacttooltip shadow-lg"
        >
          <div className="max-w-[256px] helptips markdown">
            {parsehtml(
              converter.makeHtml(tip || support_messages.missing_info)
            )}
          </div>
        </ReactTooltip>
      )}
    </>
  );
};

export default GatingIcon;
