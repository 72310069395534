// USAGE
// isAdminUser(ssr_data_profile?.id             //user_id (eg owner_id)
//         ,ssr_data_profile?.bol_staff_help    //bol_staff_help
//         ,ssr_data_profile?.staff_json        //staff_json
//         ,ssr_data_profile?.bol_admin_help    //bol_admin_help
//         ,user["id"]                           //my_id
//         ,user["bol_admin"]         //global_admin
//         ,helptyp                     //SPECIFIC PERMISSIONS
//         )

import { ssr_no_object, STAFF_PERMS } from "../config";
import { getBitwise } from "./bitoperations";
import { hasData } from "./hasData";
import { IsJsonValid, validJson } from "./isJsonValid";

export default function isAdminUser(
  user_id,
  bol_staff_help,
  staff_json,
  bol_admin_help,
  my_id,
  bol_admin,
  helptype
) {
  if (
    !hasData(my_id) ||
    my_id?.toString() == "0" ||
    !hasData(user_id) ||
    user_id?.toString() == "0"
  ) {
    return false;
  }

  const validated_staff_json = validJson(staff_json, []);

  return (
    user_id?.toString() == my_id?.toString() ||
    (bol_staff_help?.toString() == "1" &&
      isAdminLookup(my_id, validated_staff_json, helptype)) ||
    (bol_admin_help?.toString() == "1" && parseInt(bol_admin) >= 1)
  );
}

export function isAdminObject(
  user_id,
  bol_staff_help,
  staff_json,
  bol_admin_help,
  my_id,
  bol_admin
) {
  var output = ssr_no_object.ssr_isadmin;

  try {
    //guest
    if (
      !hasData(my_id) ||
      !hasData(user_id) ||
      my_id?.toString() == "0" ||
      user_id?.toString() == "0"
    ) {
      return output;
    }

    //owner
    if (
      hasData(my_id) &&
      hasData(user_id) &&
      user_id?.toString() !== "0" &&
      my_id?.toString() !== "0" &&
      user_id?.toString() == my_id?.toString()
    ) {
      output = {
        personal: true,
        global: bol_admin_help?.toString() == "1" && parseInt(bol_admin) >= 1,
        profile: true,
        chat: true,
        broadcast: true,
        api: true,
        staff: true,
      };

      return output;
    }

    //global admin
    if (bol_admin_help?.toString() == "1" && parseInt(bol_admin) >= 1) {
      output = {
        personal: false,
        global: true,
        profile: true,
        chat: true,
        broadcast: true,
        api: true,
        staff: true,
      };

      return output;
    }

    //staff
    if (
      bol_staff_help?.toString() == "1" &&
      hasData(my_id) &&
      my_id?.toString() !== "0"
    ) {
      const validated_staff_json = validJson(staff_json, []);
      const profile =
        isAdminLookup(my_id, validated_staff_json, "profile") || false;
      const chat = isAdminLookup(my_id, validated_staff_json, "chat") || false;
      const broadcast =
        isAdminLookup(my_id, validated_staff_json, "broadcast") || false;
      const api = isAdminLookup(my_id, validated_staff_json, "api") || false;
      const staff =
        isAdminLookup(my_id, validated_staff_json, "staff") || false;

      output = {
        personal: false,
        global: false,
        profile: profile,
        chat: chat,
        broadcast: broadcast,
        api: api,
        staff: staff,
      };

      return output;
    }

    return output;
  } catch (e) {
    console.log("error in ssr_isadmin", e);
    return output;
  }
}

function isAdminLookup(id, json, helptype) {
  if (!hasData(id) || !hasData(helptype)) return false;
  return (
    json.filter(
      (e) =>
        e?.id?.toString() == id?.toString() &&
        (!hasData(helptype) ||
          getBitwise(e?.permissions, Object.keys(STAFF_PERMS))[helptype])
    )?.length >= 1
  );
}

export function isAdminUserPermissions(
  user_id,
  bol_staff_help,
  staff_json,
  bol_admin_help,
  my_id,
  bol_admin,
  helptype
) {
  if (
    !hasData(my_id) ||
    my_id?.toString() == "0" ||
    !hasData(user_id) ||
    user_id?.toString() == "0"
  ) {
    return "none";
  }

  if (my_id?.toString() == user_id?.toString()) return "all";

  if (bol_admin_help?.toString() == "1" && parseInt(bol_admin) >= 1)
    return "all";

  const validated_staff_json = validJson(staff_json, []);
  if (
    bol_staff_help?.toString() == "1" &&
    validated_staff_json.filter(
      (e) =>
        e?.id?.toString() == my_id?.toString() &&
        (!hasData(helptype) ||
          getBitwise(e?.permissions, Object.keys(STAFF_PERMS))[helptype])
    )?.length >= 1
  ) {
    return validated_staff_json.filter(
      (e) => e?.id?.toString() == my_id?.toString()
    )[0]?.permissions;
  }

  return "none";
}
